import { Action } from '@ngrx/store';
import {
  CompanyProductDefinition,
  EmailListPreview,
  Group,
  GroupCategoriesHierarchy,
  GroupUsersCounts,
  Language,
  Module,
  ModuleCategoryGroup,
  ProductIndex,
  ProductWizardContext,
  QuestionList,
  ReminderEmail,
  SmsList,
  WizardSelectionGroup
} from 'app/shared/models';
import { ModuleView } from 'app/shared/product-wizard/survey/module-selection/models';
import { ValidationData } from 'app/shared/product-wizard/survey/module-selection/models/survey-wizard-question.model';

export enum Type {
  TOGGLE_MODULE = '[SURVEY_WIZARD] TOGGLE_MODULE',
  TOGGLE_EXPAND_MODULE = '[SURVEY_WIZARD] TOGGLE_EXPAND_MODULE',
  REFRESH_MODULE_RULES = '[SURVEY_WIZARD] REFRESH_MODULE_RULES',
  SELECTED_MODULES = '[SURVEY_WIZARD] SELECTED_MODULES',
  ADD_QUESTIONS_TO_MODULE = '[SURVEY_WIZARD] ADD_QUESTIONS_TO_MODULE',
  REMOVE_QUESTIONS_FROM_MODULE = '[SURVEY_WIZARD] REMOVE_QUESTIONS_FROM_MODULE',
  TOGGLE_GROUP = '[SURVEY_WIZARD] TOGGLE_GROUP',
  SET_GROUP_SELECTION = '[SURVEY_WIZARD] SET_GROUP_SELECTION',
  SET_GROUPS = '[SURVEY_WIZARD] SET_GROUPS',
  SET_PARTICIPANTS_COUNTS = '[SURVEY_WIZARD] SET_PARTICIPANTS_COUNTS',
  SET_COMMUNICATION_METHOD = '[SURVEY_WIZARD] SET_COMMUNICATION_METHOD',
  SET_COMMUNICATION_METHOD_SUCCESS = '[SET_COMMUNICATION_METHOD_SUCCESS] SET_COMMUNICATION_METHOD_SUCCESS',
  SET_ACTIVATION_EMAIL = '[SURVEY_WIZARD] SET_ACTIVATION_EMAIL',
  SET_PIN_CODE_EMAIL = '[SURVEY_WIZARD] SET_PIN_CODE_EMAIL',
  SET_REPORT_EMAIL = '[SURVEY_WIZARD] SET_REPORT_EMAIL',
  SET_ACTIVATION_SMS = '[SURVEY_WIZARD] SET_ACTIVATION_SMS',
  SET_PIN_CODE_SMS = '[SURVEY_WIZARD] SET_PIN_CODE_SMS',
  SET_WELCOME_SMS = '[SURVEY_WIZARD] SET_WELCOME_SMS',
  SET_WELCOME_LANGUAGE = '[SURVEY_WIZARD] SET_WELCOME_LANGUAGE',
  SET_AUTO_REPORT = '[SURVEY_WIZARD] SET_AUTO_REPORT',
  INIT_AUTO_REPORT = '[SURVEY_WIZARD] INIT_AUTO_REPORT',
  SET_PREVIEW_LANGUAGE = '[SURVEY_WIZARD] SET_PREVIEW_LANGUAGE',
  SET_PINCODE_LANGUAGE = '[SURVEY_WIZARD] SET_PINCODE_LANGUAGE',
  SET_EMAIL_PREVIEW_LANGUAGE = '[SURVEY_WIZARD] SET_EMAIL_PREVIEW_LANGUAGE',
  SET_RESPONSETIME_SELECTION = '[SURVEY_WIZARD] SET_RESPONSETIME_SELECTION',
  SET_START_DATE_SELECTION = '[SURVEY_WIZARD] SET_START_DATE_SELECTION',
  SET_START_DATE = '[SURVEY_WIZARD] SET_START_DATE',
  SET_END_DATE = '[SURVEY_WIZARD] SET_END_DATE',
  SET_REPORT_DATE = '[SURVEY_WIZARD] SET_REPORT_DATE',
  SET_DIRECT_REPORT = '[SURVEY_WIZARD] SET_DIRECT_REPORT',
  SET_REMINDER_EMAILS = '[SURVEY_WIZARD] SET_REMINDER_EMAILS',
  SET_REMINDER_EMAIL = '[SURVEY_WIZARD] SET_REMINDER_EMAIL',
  SET_START_TIME = '[SURVEY_WIZARD] SET_START_TIME',
  SET_NAME = '[SURVEY_WIZARD] SET_NAME',
  SET_NAME_SUCCESS = '[SURVEY_WIZARD] SET_NAME_SUCCESS',
  REMOVE_QUESTION = '[SURVEY_WIZARD] REMOVE_QUESTION',
  TOGGLE_QUESTION = '[SURVEY_WIZARD] TOGGLE_QUESTION',
  CALCULATE_SURVEY_INDEXES = '[SURVEY_WIZARD] CALCULATE_SURVEY_INDEXES',
  SET_INDEXES = '[SURVEY_WIZARD] SET_INDEXES',
  GET_INDEX_INFO = '[SURVEY_WIZARD] GET_INDEX_INFO',
  GET_INDEX_INFO_SUCCESS = '[SURVEY_WIZARD] GET_INDEX_INFO_SUCCESS',
  SET_SELECTED_HIERARCHY = '[SURVEY_WIZARD] SET_SELECTED_HIERARCHY',
  SET_SURVEY_ID = '[SURVEY_WIZARD] SET_SURVEY_ID',
  SORT_MODULES = '[SURVEY_WIZARD] SORT_MODULES',
  SET_COMMUNICATION_ENABLED = '[SURVEY_WIZARD] SET_COMMUNICATION_ENABLED',
  SETUP_CONTENT = '[SURVEY_WIZARD] SETUP_CONTENT',
  SETUP_CONTENT_SUCCESS = '[SURVEY_WIZARD] SETUP_CONTENT_SUCCESS',
  SET_CHANGED = '[SURVEY_WIZARD] SET_CHANGED',
  SET_CHANGED_SUCCESS = '[SURVEY_WIZARD] SET_CHANGED_SUCCESS',
  UNLOAD_SURVEY_WIZARD = '[SURVEY_WIAZRD] UNLOAD_SURVEY_WIZARD',
  SET_SELECTED_MODULES = '[SURVEY_WIZARD] SET_SELECTED_MODULES',
  SET_SELECTED_MODULES_SUCCESS = '[SURVEY_WIZARD] SET_SELECTED_MODULES_SUCCESS',
  SET_MODULE_ID = '[SURVEY_WIZARD] SET_MODULE_ID',
  SET_MODULE_ID_SUCCESS = '[SURVEY_WIZARD] SET_MODULE_ID_SUCCESS'
}

export class SetSelectedModules implements Action {
  readonly type = Type.SET_SELECTED_MODULES
  constructor(public payload: ModuleView) { }
}
export class SetSelectedModulesSuccess implements Action {
  readonly type = Type.SET_SELECTED_MODULES_SUCCESS
  constructor(public payload: ModuleView) { }
}
export class ToggleModule implements Action {
  readonly type = Type.TOGGLE_MODULE;
  constructor(public payload: Module) { }
}
export class ToggleExpandModule implements Action {
  readonly type = Type.TOGGLE_EXPAND_MODULE;
  constructor(public payload: number) { }
}
export class RefreshModuleRules implements Action {
  readonly type = Type.REFRESH_MODULE_RULES;
  constructor(public payload: Module) { }
}

export class SelectedModules implements Action {
  readonly type = Type.SELECTED_MODULES;
  constructor(public payload: number) { }
}

export class AddQuestionsToModule implements Action {
  readonly type = Type.ADD_QUESTIONS_TO_MODULE;
  constructor(
    public payload: {
      id: number;
      questions: QuestionList[];
      pageGroupId?: number;
    }
  ) { }
}

export class RemoveQuestionsFromModule implements Action {
  readonly type = Type.REMOVE_QUESTIONS_FROM_MODULE;
  constructor(
    public payload: {
      moduleId: number;
      pageGroupId: number;
      questionId: number;
    }
  ) { }
}
export class SetName implements Action {
  readonly type = Type.SET_NAME;
  constructor(public payload: string) { }
}
export class SetNameSuccess implements Action {
  readonly type = Type.SET_NAME_SUCCESS;
  constructor(public payload: string) { }
}

export class ToggleGroup implements Action {
  readonly type = Type.TOGGLE_GROUP;
  constructor(public payload: Group) { }
}

export class SetGroupSelection implements Action {
  readonly type = Type.SET_GROUP_SELECTION;
  constructor(public payload: string) { }
}

export class SetGroups implements Action {
  readonly type = Type.SET_GROUPS;
  constructor(public payload: WizardSelectionGroup[]) { }
}
export class SetSelectedHierarchy implements Action {
  readonly type = Type.SET_SELECTED_HIERARCHY;
  constructor(public payload: GroupCategoriesHierarchy) { }
}
export class SetParticipantsCounts implements Action {
  readonly type = Type.SET_PARTICIPANTS_COUNTS;
  constructor(public payload: GroupUsersCounts) { }
}
export class SetStartDate implements Action {
  readonly type = Type.SET_START_DATE;
  constructor(public payload: Date) { }
}
export class SetEndDate implements Action {
  readonly type = Type.SET_END_DATE;
  constructor(public payload: Date) { }
}
export class SetReminderEmails implements Action {
  readonly type = Type.SET_REMINDER_EMAILS;
  constructor(public payload: ReminderEmail[]) { }
}
export class SetReminderEmail implements Action {
  readonly type = Type.SET_REMINDER_EMAIL;
  constructor(
    public payload: { index: number; reminderEmail: ReminderEmail }
  ) { }
}
export class SetReportDate implements Action {
  readonly type = Type.SET_REPORT_DATE;
  constructor(public payload: Date) { }
}
export class SetDirectReport implements Action {
  readonly type = Type.SET_DIRECT_REPORT;
  constructor(public payload: boolean) { }
}
export class SetStartTime implements Action {
  readonly type = Type.SET_START_TIME;
  constructor(
    public payload: {
      direct: boolean;
      hour: number;
      minute: number;
    }
  ) { }
}

export class SetCommunicationEnabled implements Action {
  readonly type = Type.SET_COMMUNICATION_ENABLED;
  constructor(public payload: boolean) { }
}

export class SetCommunicationMethod implements Action {
  readonly type = Type.SET_COMMUNICATION_METHOD;
  constructor(public payload: string, public init: boolean = false) { }
}

export class SetCommunicationMethodSuccess implements Action {
  readonly type = Type.SET_COMMUNICATION_METHOD_SUCCESS;
  constructor(public payload: string, public init: boolean = false) { }
}

export class SetActivationEmail implements Action {
  readonly type = Type.SET_ACTIVATION_EMAIL;
  constructor(public payload: EmailListPreview) { }
}
export class SetActivationSms implements Action {
  readonly type = Type.SET_ACTIVATION_SMS;
  constructor(public payload: SmsList) { }
}

export class SetPreviewLanguage implements Action {
  readonly type = Type.SET_PREVIEW_LANGUAGE;
  constructor(public payload: Language) { }
}
export class SetPinCodeEmail implements Action {
  readonly type = Type.SET_PIN_CODE_EMAIL;
  constructor(public payload: EmailListPreview) { }
}

export class SetPinCodeSms implements Action {
  readonly type = Type.SET_PIN_CODE_SMS;
  constructor(public payload: SmsList) { }
}
export class SetPincodeLanguage implements Action {
  readonly type = Type.SET_PINCODE_LANGUAGE;
  constructor(public payload: number) { }
}
export class SetReportEmail implements Action {
  readonly type = Type.SET_REPORT_EMAIL;
  constructor(public payload: EmailListPreview) { }
}
export class SetWelcomeSms implements Action {
  readonly type = Type.SET_WELCOME_SMS;
  constructor(public payload: SmsList) { }
}
export class SetWelcomeLanguage implements Action {
  readonly type = Type.SET_WELCOME_LANGUAGE;
  constructor(public payload: number) { }
}
export class SetAutoReport implements Action {
  readonly type = Type.SET_AUTO_REPORT;
  constructor(public payload: boolean) { }
}
export class InitAutoReport implements Action {
  readonly type = Type.INIT_AUTO_REPORT;
  constructor(
    public payload: {
      context: ProductWizardContext;
      autoReportDefaultValue: boolean;
    }
  ) { }
}
export class RemoveQuestion implements Action {
  readonly type = Type.REMOVE_QUESTION;
  constructor(public payload: { moduleId: number; questionId: number }) { }
}
export class ToggleQuestion implements Action {
  readonly type = Type.TOGGLE_QUESTION;
  constructor(public payload: { moduleId: number; questionId: number }) { }
}
export class CalculateSurveyIndexes implements Action {
  readonly type = Type.CALCULATE_SURVEY_INDEXES;
}
export class SetIndexes implements Action {
  readonly type = Type.SET_INDEXES;
  constructor(public payload: ValidationData[]) { }
}
export class GetIndexInfo implements Action {
  public readonly type = Type.GET_INDEX_INFO;
}

export class GetIndexInfoSuccess implements Action {
  public readonly type = Type.GET_INDEX_INFO_SUCCESS;
  constructor(public payload: ProductIndex[]) { }
}
export class SetSurveyId implements Action {
  readonly type = Type.SET_SURVEY_ID;
  constructor(public payload: number) { }
}
export class SetModuleId implements Action {
  readonly type = Type.SET_MODULE_ID;
  constructor(public payload: number) { }
}
export class SetModuleIdSuccess implements Action {
  readonly type = Type.SET_MODULE_ID_SUCCESS;
  constructor(public payload: number) { }
}
export class SortModules implements Action {
  readonly type = Type.SORT_MODULES;
  constructor(public payload: ModuleView[]) { }
}
export class SetupContent implements Action {
  readonly type = Type.SETUP_CONTENT;
  constructor(
    public payload: {
      companyProductDefinition: CompanyProductDefinition;
      selectedModules: ModuleView[];
    }
  ) { }
}
export class SetupContentSuccess implements Action {
  readonly type = Type.SETUP_CONTENT_SUCCESS;
  constructor(public payload: ModuleCategoryGroup[]) { }
}


export class UnloadSurveyWizard implements Action {
  readonly type = Type.UNLOAD_SURVEY_WIZARD;
}

export type Actions =
  | SetModuleId
  | SetModuleIdSuccess
  | SetSelectedModules
  | SetSelectedModulesSuccess
  | ToggleModule
  | ToggleExpandModule
  | RefreshModuleRules
  | AddQuestionsToModule
  | RemoveQuestionsFromModule
  | SelectedModules
  | SetName
  | SetNameSuccess
  | ToggleGroup
  | SetGroupSelection
  | SetGroups
  | SetSelectedHierarchy
  | SetParticipantsCounts
  | SetStartDate
  | SetEndDate
  | SetReportDate
  | SetDirectReport
  | SetReminderEmails
  | SetReminderEmail
  | SetStartTime
  | SetCommunicationEnabled
  | SetCommunicationMethod
  | SetCommunicationMethodSuccess
  | SetActivationEmail
  | SetActivationSms
  | SetPinCodeEmail
  | SetPinCodeSms
  | SetPreviewLanguage
  | SetPincodeLanguage
  | SetReportEmail
  | SetWelcomeSms
  | SetWelcomeLanguage
  | SetAutoReport
  | InitAutoReport
  | RemoveQuestion
  | ToggleQuestion
  | CalculateSurveyIndexes
  | SetIndexes
  | GetIndexInfo
  | GetIndexInfoSuccess
  | SetSurveyId
  | SortModules
  | SetupContent
  | SetupContentSuccess
  | UnloadSurveyWizard;
